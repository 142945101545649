<template>
    <div>
        <v-app-bar clipped-left app color="#343642" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>
                Welcome to DG Ventures Project Management Software —
                {{ $date().format('M/DD/YYYY') }} — Week {{ $date().week() }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="get_user.id" style="display:flex;align-items:center;">
                <label class="mr-5">
                    Hi, {{ get_user.name }}
                </label>
                <small style="display:flex;align-items:center;" class="mr-5">
                    <v-icon small>mdi-badge-account-outline</v-icon>
                    {{ get_user.role.name }}
                </small>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="openNotifications()" text>
                            <v-icon class="mr-3" :color="notifications.filter(x => x.is_read == 0).length > 0 ? 'red' : 'white'">mdi-bell</v-icon>
                            <div>
                                Notifications
                                <br>
                                <small>{{ notifications.filter(x => x.is_read == 0).length }} new</small>
                            </div>
                        </v-btn>
                    </template>
                    <v-card
                        style="width:800px;border: solid 1px grey;padding-bottom:20px;overflow-y:scroll;max-height:800px;">
                        <v-card-title>
                            Recent Notifications
                        </v-card-title>
                        <v-card-subtitle v-if="notifications.length == 0">
                            No notifications to display
                        </v-card-subtitle>
                        <div v-for="(notification, index) in notifications" :key="index" style="padding-bottom:0px;">
                            <v-card-text v-if="notification.task != null">
                                <v-btn v-if="notification.task != null"
                                    @click="openTaskDetailsDialog(notification.task)"
                                    style="font-size:16px;color:black;text-transform:initial;text-align:left;letter-spacing:initial;justify-content:initial;height:auto;max-width:180px;text-align:left;"
                                    block>
                                    <div>
                                        <div>
                                            <v-icon class="mt-1"
                                                :color="getColor(notification.task.priority_id)">mdi-circle-medium</v-icon>
                                            <label v-html="notification.content"
                                                style="white-space: normal; overflow-wrap: break-word;"></label>
                                        </div>
                                        <div style="color:grey;font-size:14px;" class="mt-1">
                                            <v-icon class="mt-1" style="visibility:hidden;">mdi-circle-medium</v-icon>
                                            {{ $date(notification.created_at).fromNow() }}
                                            on {{ $date(notification.created_at).format('MMM DD, YYYY hh:mm A') }}
                                        </div>
                                    </div>
                                </v-btn>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-menu>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                            Account
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item style="color:lightgrey;">
                            <v-list-item-title>Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item style="color:lightgrey;">
                            <v-list-item-title>Change Password</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout()" style="cursor:pointer;">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-if="get_user.id" :value="drawer" app
            v-click-outside="{ handler: closeDrawer, closeConditional: onCloseConditional }" clipped
            style="background-color: #E9E9E9;">
            <v-list>
                <v-list-item>
                    <v-menu v-model="select_company_menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item class="mx-4 mt-3 mb-3 company-menu" link two-line v-bind="attrs" v-on="on"
                                v-if="get_selected_company.id">
                                <v-list-item-content>
                                    <v-list-item-title style="font-weight:500;">
                                        {{ get_selected_company.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small color="grey lighten-1">mdi-unfold-more-horizontal</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item class="mx-4 mt-3 mb-3 company-menu" link two-line v-bind="attrs" v-on="on"
                                v-else>
                                <v-list-item-content>
                                    <v-list-item-title>Select Company</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small color="grey lighten-1">mdi-unfold-more-horizontal</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <v-card max-width="450" style="border:1px solid #CCC;">
                            <v-card-title>
                                <v-text-field placeholder="Search Company" class="mx-3" hide-details
                                    v-model="search"></v-text-field>
                                <v-btn icon @click="openCreateCompanyDialog()"
                                    v-if="get_user.role_id < 3"><v-icon>mdi-plus</v-icon></v-btn>
                            </v-card-title>
                            <v-divider class="mx-6"></v-divider>
                            <v-card-text>
                                <v-data-table :headers="headers" :items="[{ name: 'All' }, ...get_companies]"
                                    :search="search" hide-default-header single-select :sort-by.sync="sort_by"
                                    :sort-desc.sync="sort_desc" style="cursor:pointer;">
                                    <template v-slot:item="{ item }">
                                        <tr @click="selectCompany(item)">
                                            <td>
                                                <label v-if="item.name == 'All'">
                                                    <b>{{ item.name }}</b>
                                                </label>
                                                <label v-else>
                                                    {{ item.name }}
                                                </label>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-list-item>
                <v-list-item :link="true" class="mx-2" to="/dashboard">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :link="true" class="mx-2" to="/messages">
                    <v-list-item-icon>
                        <v-icon>mdi-chat</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Messages</v-list-item-title>
                    </v-list-item-content>
                    <span v-if="get_unread_count > 0" class="notification-bubble">
                        {{ get_unread_count }}
                    </span>
                </v-list-item>
                <v-menu v-model="select_project_menu" open-on-hover :close-on-content-click="false" :nudge-width="200"
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item :link="(get_selected_company.name != null)" v-bind="attrs" v-on="on"
                            :class="$route.name == 'ViewProject' ? 'mx-2 project-active' : 'mx-2'"
                            :style="(get_selected_company.name == null) ? 'color:lightgrey;cursor:initial;' : ''">
                            <v-list-item-icon>
                                <v-icon :style="(get_selected_company.name == null) ? 'color:lightgrey;' : ''">
                                    mdi-view-dashboard
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Projects
                                    <v-icon v-if="get_selected_company.name != null">mdi-menu-right</v-icon>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card v-if="get_selected_company.name != null" max-width="400" style="border:1px solid #CCC;">
                        <v-card-title>
                            <v-text-field placeholder="Search Projects" class="mx-3" hide-details
                                v-model="search_project"></v-text-field>
                            <v-btn icon @click="openCreateProjectDialog()" v-if="get_user.role_id < 3">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider class="mx-6"></v-divider>
                        <v-card-text>
                            <v-data-table :headers="project_headers" :items="projects" :search="search_project"
                                hide-default-footer hide-default-header single-select style="cursor:pointer;">
                                <template v-slot:item="{ item }">
                                    <tr @click="selectProject(item)">
                                        <td>
                                            {{ item.name }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-menu>
                <v-list-item :link="(get_selected_company.name != null)" @click="openProjectsNavigation()" class="mx-2"
                    :to="(get_selected_company.name != null) ? '/meetings' : ''"
                    :style="(get_selected_company.name == null) ? 'color:lightgrey;cursor:initial;' : ''">
                    <v-list-item-icon>
                        <v-icon :style="(get_selected_company.name == null) ? 'color:lightgrey;' : ''">
                            mdi-account-network
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Meetings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(item) in sidebars" :key="item.id"
                    :link="(!item.disabled && get_selected_company.name != null)" class="mx-2"
                    :to="(item.disabled == false && get_selected_company.name != null) ? item.route : ''"
                    :style="(item.disabled | get_selected_company.name == null) ? 'color:lightgrey;cursor:initial;' : ''">
                    <v-list-item-icon>
                        <v-icon :style="(item.disabled | get_selected_company.name == null) ? 'color:lightgrey;' : ''">
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list v-if="get_user.role_id < 3">
                    <v-divider class="mb-2"></v-divider>
                    <div class="mb-4">
                        <label class="mx-6" style="font-weight:bold;color:#7C7C7C">
                            Agency Menu
                        </label>
                    </div>
                    <v-list-item link class="mx-2" to="/users">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Users</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="mx-2" to="/user-activity">
                        <v-list-item-icon>
                            <v-icon>mdi-gesture-tap</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>User Activity</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="mx-2" to="" style="color:lightgrey;cursor:initial;">
                        <v-list-item-icon>
                            <v-icon style="color:lightgrey;">mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Agency Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
        <v-row justify="center">
            <v-dialog max-width="600" v-model="create_company_dialog" persistent>
                <v-card>
                    <v-card-title>
                        <small>Add Company</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="closeCreateCompanyDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field dense label="Name *" outlined v-model="new_company.name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense label="Email" outlined v-model="new_company.email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense label="Number" outlined v-model="new_company.number">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense label="Website" outlined v-model="new_company.website">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="createCompany()" dark>Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog max-width="600" v-model="create_project_dialog" persistent>
                <v-card>
                    <v-card-title>
                        <small>Add Project</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="closeCreateProjectDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field dense label="Name *" outlined v-model="new_project.name">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="createProject()" dark>Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <TaskNavigationComponent :task_details_dialog="task_details_dialog"
            :can_close_task_details_dialog="can_close_task_details_dialog" :users="get_users" @updateTask="updateTask"
            @closeTaskDetailsDialog="closeTaskDetailsDialog" />

        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskNavigationComponent from '../components/modules/TaskNavigationComponent.vue'
export default {
    components: {
        TaskNavigationComponent
    },
    data: () => ({
        drawer: true,
        select_company_menu: false,
        select_project_menu: false,
        search: null,
        search_project: null,
        projects: [],
        sort_by: 'name',
        sort_desc: false,
        headers: [
            { text: 'Company Name', value: 'name', sortable: false }
        ],
        project_headers: [
            { text: 'Project Name', value: 'name', sortable: false }
        ],
        create_company_dialog: false,
        new_company: {
            name: null,
            email: null,
            number: null,
            website: null,
        },
        new_project: {
            name: null,
        },
        sidebars: [
            { id: 4, disabled: true, icon: "mdi-chart-bar", label: "Stats", route: "/stats" },
            { id: 6, disabled: true, icon: "mdi-email", label: "Email", route: "/email" },
            { id: 7, disabled: true, icon: "mdi-file-document", label: "Documents / SOPs", route: "/documents" },
            { id: 8, disabled: true, icon: "mdi-laptop", label: "Company Info", route: "/company" },
            { id: 9, disabled: true, icon: "mdi-list-box", label: "KPIs", route: "/kpi" },
        ],
        projects_navigation: false,
        create_project_dialog: false,
        is_project_page: false,
        notifications: [],
        task_details_dialog: false,
        selected_task: null,
        can_close_task_details_dialog: false,
        original_selected_task: {},
    }),
    async mounted() {
        this.getCompanies()
        this.getProjects()
        this.getUnreadMessages()
        this.getNotifications()
        await this.$echo.private('notification.' + this.get_user.id)
            .listen('NotificationSent', () => {
                this.getNotifications()
            })
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_users: 'tasks/get_users',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
            get_unread_count: 'messages/get_unread_count',
            get_selected_task: 'tasks/get_selected_task',
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getCompanies() {
            if (this.get_user.role_id == 1) {
                this.$axios.get('api/companies/get_companies')
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('companies/set_companies', data.data)
                        }
                    })
            }
            else {
                this.$axios.get('api/companies/get_companies_by_access')
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('companies/set_companies', data.data)
                        }
                    })
            }
        },
        async getProjects() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            if (this.get_user.role_id == 1) {
                this.$axios.get('api/projects/get_projects', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.projects = data.data
                        }
                    })
            }
            else {
                this.$axios.get('api/projects/get_projects_by_access', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.projects = data.data
                        }
                    })
            }
        },
        async getUnreadMessages() {
            let payload = {
                receiver_id: this.get_user.id,
                is_read: false,
            }
            this.$axios.get('api/messages/get_messages', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('messages/set_unread_count', data.data.length)
                    }
                })
        },
        async getNotifications() {
            let payload = {
                receiver_id: this.get_user.id,
            }
            console.log('getting notifications')
            this.$axios.get('api/notifications/get_notifications', payload)
                .then(({ data }) => {
                    if (data.response) {
                        console.log(data)
                        this.notifications = data.data
                    }
                })
        },
        async openNotifications() {
            await this.$axios.post(`api/notifications/mark_notifications_as_read/${this.get_user.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.notifications.forEach(notification => {
                            notification.is_read = 1
                        })
                    }
                })
        },
        openTaskDetailsDialog(item) {
            this.task_details_dialog = true
            this.$store.dispatch('tasks/set_selected_task', item)
            this.original_selected_task = { ...item }
            setTimeout(() => {
                this.can_close_task_details_dialog = true
            }, 100)
        },
        closeTaskDetailsDialog() {
            this.task_details_dialog = false
            let selected_task = {
                name: this.get_selected_task.name,
                company_id: this.get_selected_task.company_id,
                project_id: this.get_selected_task.project_id,
                priority_id: this.get_selected_task.priority_id,
                status_id: this.get_selected_task.status_id,
                due_date: this.get_selected_task.due_date,
                // allocated_time: this.get_selected_task.allocated_time,
            }
            let original_task = {
                name: this.original_selected_task.name,
                company_id: this.original_selected_task.company_id,
                project_id: this.original_selected_task.project_id,
                priority_id: this.original_selected_task.priority_id,
                status_id: this.original_selected_task.status_id,
                due_date: this.original_selected_task.due_date,
                // allocated_time: this.original_selected_task.allocated_time,
            }
            console.log(JSON.stringify(selected_task))
            console.log(JSON.stringify(original_task))
            if (JSON.stringify(selected_task) != JSON.stringify(original_task)) {
                console.log('yes')
                this.updateTask(this.get_selected_task)
            }
            this.$store.dispatch('tasks/set_selected_task', {})
            this.original_selected_task = {}
        },
        async updateTask(item) {
            let payload = {
                company_id: item.company_id,
                assignee_id: item.assignee_id,
                project_id: item.project_id,
                name: item.name,
                status_id: item.status_id,
                description: item.description,
                priority_id: item.priority_id,
                due_date: item.due_date,
                allocated_time: item.allocated_time,
            }
            await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        getColor(priority_id) {
            switch (priority_id) {
                case 1:
                    return 'red'
                case 2:
                    return 'orange'
                case 3:
                    return '#FFC605'
                case 4:
                    return 'blue'
                default:
                    return 'grey'
            }
        },
        selectCompany(item) {
            if (item.name == 'All') {
                this.$store.dispatch('companies/set_selected_company', {})
            }
            else {
                this.$store.dispatch('companies/set_selected_company', item)
            }
            this.select_company_menu = false
            if (this.$route.name == 'Dashboard') {
                this.$router.go(0)
            }
            else {
                this.$router.push({ name: 'Dashboard' }).then(() => { this.$router.go(0) })
            }
        },
        openCreateCompanyDialog() {
            this.create_company_dialog = true
        },
        closeCreateCompanyDialog() {
            this.create_company_dialog = false
        },
        async createCompany() {
            await this.$axios.post('api/companies/create_company', this.new_company)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateCompanyDialog()
                        this.selectCompany(data.data)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCreateProjectDialog() {
            this.create_project_dialog = true
        },
        closeCreateProjectDialog() {
            this.create_project_dialog = false
        },
        async createProject() {
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.new_project,
            }
            await this.$axios.post('api/projects/create_project', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateProjectDialog()
                        this.selectProject(data.data)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        selectProject(item) {
            this.$router.push({ name: 'ViewProject', params: { id: item.id } })
        },
        closeDrawer() {
            this.drawer = false
        },
        onCloseConditional() {
            return this.drawer && this.$vuetify.breakpoint.mdAndDown
        },
        openProjectsNavigation() {
            this.projects_navigation = true
        },
        closeProjectsNavigation() {
            this.projects_navigation = false
        },
        async logout() {
            await this.$axios.post('api/logout')
            this.$store.dispatch('auth/set_user', {})
            this.$store.dispatch('auth/set_token', '')
            this.$store.dispatch('companies/set_selected_company', [])
            this.$store.dispatch('tasks/set_tasks', [])
            this.$store.dispatch('tasks/set_assigned_tasks', [])
            this.$router.push({ name: 'Login' })
        },
    },
}
</script>

<style scoped>
.company-menu {
    border: 1px solid #CCC;
    border-radius: 10px;
    cursor: pointer;
}

.company-menu-title {
    font-family: 'Manrope';
}

.company-menu-subtitle {
    font-size: 12px;
    font-family: 'Manrope';
}

.v-app-bar-title__content {
    width: 50% !important;
}

.main-app-bar {
    background-color: #FFF !important;
}

.main-app-bar-title {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: bolder !important;
}

.main-app-bar-subtitle {
    font-size: 10px;
}

.v-menu__content {
    box-shadow: none;
}

.v-data-table__wrapper {
    tr {
        cursor: pointer;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}

:deep(.project-active::before) {
    opacity: 0.12 !important;
}

.notification-bubble {
    position: absolute;
    top: 35%;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 5px;
    font-size: 0.75rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
}

:deep(.v-btn span) {
    display: contents !important;
    white-space: initial;
}
</style>