<template>
    <v-container>
        <v-navigation-drawer :value="task_details_dialog"
            v-click-outside="{ handler: closeTaskDetailsDialog, closeConditional: onCloseConditional, include: includeFunction }"
            clipped app right temporary :width="navigation.width" ref="drawer">
            <v-card v-if="get_selected_task" elevation="0" style="padding-bottom:200px;" class="included"
                id="navigation">
                <v-card-title style="align-items:start;">
                    <div style="width:200px;">
                        <v-select v-model="get_selected_task.company_id"
                            :items="[{ name: 'No Company', id: null }, ...get_companies]" item-text="name"
                            item-value="id" dense outlined hide-details label="Company" @change="changeCompany()">
                        </v-select>
                    </div>
                    <div style="width:200px;" class="ml-5">
                        <v-select v-model="get_selected_task.project_id" :items="[
                            { name: 'No Project', id: null },
                            ...projects.filter(x => x.company_id == get_selected_task.company_id)
                        ]" @change="updateTask()" item-text="name" item-value="id" dense outlined hide-details
                            label="Project" :disabled="!get_selected_task.company_id">
                        </v-select>
                        <!-- <v-btn @click="openProject()"
                            v-if="get_selected_task.project_id && get_selected_task.company_id" color="primary" text>Go
                            to
                            Project</v-btn> -->
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeTaskDetailsDialog()" text>Close</v-btn>
                </v-card-title>
                <v-card-title>
                    <v-text-field v-model="get_selected_task.name" label="Task Name"></v-text-field>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <h4>Created</h4>
                        </v-col>
                        <v-col cols="3">
                            {{ $date(get_selected_task.created_at).format('M/DD/YYYY') }} —
                            Week {{ $date(get_selected_task.created_at).week() }}
                        </v-col>
                        <v-col cols="3">
                            <h4>Meeting</h4>
                        </v-col>
                        <v-col cols="3">
                            <a @click="openMeeting()" v-if="get_selected_task.meeting" color="primary">
                                {{ get_selected_task.meeting ? get_selected_task.meeting.name : null }}
                            </a>
                            <label v-else>None</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <h4>Assigned By</h4>
                        </v-col>
                        <v-col cols="3">
                            {{ get_selected_task.creator ? get_selected_task.creator.name : 'None' }}
                        </v-col>
                        <v-col cols="3">
                            <h4>Assigned To</h4>
                        </v-col>
                        <v-col cols="3">
                            <v-combobox v-model="get_selected_task.assignees" :items="users" multiple item-text="name"
                                item-value="id" dense hide-details style="margin-top:-4px;">
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <h4>Due Date</h4>
                        </v-col>
                        <v-col cols="3">
                            <v-menu v-model="due_date_menu" :close-on-content-click="false" :nudge-left="140"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip @click.stop v-bind="attrs" v-on="on"
                                        style="color:black;width:90%;cursor: pointer;background-color:transparent;margin-top:-4px;  ">
                                        <label v-if="get_selected_task.due_date" style="font-size:12px;cursor:pointer;">
                                            {{ get_selected_task.due_date }}
                                        </label>
                                        <label v-else style="font-size:12px;cursor:pointer;color:grey;">
                                            None
                                        </label>
                                    </v-chip>
                                </template>
                                <v-date-picker v-model="get_selected_task.due_date"
                                    @input="due_date_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3">
                            <h4>Allocated Time</h4>
                        </v-col>
                        <v-col cols="3">
                            <v-btn text style="margin-top:-4px;">
                                <vue-timepicker v-model="get_selected_task.allocated_time" format="HH:mm"
                                    :minute-interval="5" hide-clear-button></vue-timepicker>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <h4>Priority</h4>
                        </v-col>
                        <v-col cols="3">
                            <v-select v-model="get_selected_task.priority_id"
                                :items="[{ name: 'None', id: null }, ...get_priorities]" item-text="name"
                                item-value="id" dense hide-details class="hide-border" append-icon=""
                                style="margin-top:-4px;">
                                <template #selection="{ item }">
                                    <v-chip style="color:white;width:90%;cursor: pointer;justify-content:center;"
                                        :color="item.color">
                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="3">
                            <h4>Status</h4>
                        </v-col>
                        <v-col cols="3">
                            <v-select v-model="get_selected_task.status_id"
                                :items="[{ name: 'None', id: null }, ...get_statuses]" item-text="name" item-value="id"
                                dense hide-details class="hide-border" append-icon="" style="margin-top:-4px;">
                                <template #selection="{ item }">
                                    <v-chip style="color:white;width:90%;cursor: pointer;justify-content:center;"
                                        :color="item.color">
                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <h4>Description</h4>
                            <wysiwyg v-model="get_selected_task.description" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <h4>Subtasks</h4>
                            <v-data-table v-if="get_selected_task.subtasks"
                                :items="get_selected_task.subtasks.filter(x => selected_statuses.includes(x.status_id) && selected_priorities.includes(x.priority_id))"
                                :headers="subtask_headers" :hide-default-header="get_selected_task.subtasks.length == 0"
                                hide-default-footer disable-pagination>
                                <template v-slot:item="{ item }">
                                    <tr style="cursor:initial;background-color:transparent;">
                                        <td>
                                            <div style="display:flex;align-items:center;">
                                                <v-text-field v-model="item.name" @keyup.enter="updateSubtask(item)"
                                                    dense hide-details class="hide-border"></v-text-field>
                                            </div>
                                        </td>
                                        <td>
                                            <v-select v-model="item.assignee_id" :items="users"
                                                @change="updateSubtask(item)" @click.stop item-text="name"
                                                item-value="id" dense hide-details class="hide-border">
                                            </v-select>
                                        </td>
                                        <td>
                                            <v-select v-model="item.priority_id" @change="updateSubtask(item)"
                                                @click.stop :items="[{ name: 'None', id: null }, ...get_priorities]"
                                                item-text="name" item-value="id" dense hide-details class="hide-border"
                                                append-icon="">
                                                <template #selection="{ item }">
                                                    <v-chip
                                                        style="color:white;width:90%;cursor: pointer;justify-content:center;"
                                                        :color="item.color">
                                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                                    </v-chip>
                                                </template>
                                            </v-select>
                                        </td>
                                        <td>
                                            <v-select v-model="item.status_id" @change="updateSubtask(item)" @click.stop
                                                :items="[{ name: 'None', id: null }, ...get_statuses]" item-text="name"
                                                item-value="id" dense hide-details class="hide-border" append-icon="">
                                                <template #selection="{ item }">
                                                    <v-chip
                                                        style="color:white;width:90%;cursor: pointer;justify-content:center;"
                                                        :color="item.color">
                                                        <strong style="font-size:12px;">{{ item.name }}</strong>
                                                    </v-chip>
                                                </template>
                                            </v-select>
                                        </td>
                                        <td>
                                            <v-menu right bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click.stop small dense icon v-bind="attrs" v-on="on">
                                                        <v-icon color="black">mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="deleteSubtask(item)">
                                                        <v-list-item-title>
                                                            Delete
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </template>
                                <!-- eslint-disable-next-line -->
                                <template v-slot:body.append>
                                    <tr v-if="create_quick_task">
                                        <td>
                                            <v-text-field v-model="new_subtask.name" ref="quickSubtask"
                                                @blur="closeCreateQuickTask()" @keyup.enter="createSubtask()" dense
                                                hide-details></v-text-field>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                            <v-btn @click="openCreateQuickTask()" v-if="create_quick_task == false" text class="ml-5">
                                <v-icon class="mr-2">mdi-plus</v-icon>
                                Add Subtask
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row style="min-height:150px;padding-bottom:50px;">
                        <v-col cols="12">
                            <h4>COMMENTS</h4>
                            <div v-if="get_selected_task.task_comments && get_selected_task.task_comments.length == 0"
                                class="mt-5">
                                No comments yet
                            </div>
                            <v-list-item v-for="(task_comment, index) in get_selected_task.task_comments" :key="index">
                                <div class="mr-5">
                                    <v-avatar :color="getColor(task_comment.user.name)" size="35">
                                        <b style="color:white;">
                                            {{ task_comment.user.name[0] }}
                                        </b>
                                    </v-avatar>
                                </div>
                                <v-list-item-content>
                                    <v-list-item-title style="font-size:14px;">
                                        <span style="font-weight:500;">{{ task_comment.user.name }}</span>
                                        <span style="color:grey;margin-left:10px;">
                                            {{ $date(task_comment.created_at).fromNow() }} on
                                        </span>
                                        <span style="color:grey;margin-left:5px;font-size:12px;">
                                            {{ $date(task_comment.created_at).format('MMM DD, YYYY hh:mm A') }}
                                        </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-show="task_comment.id != comment_to_edit.id"
                                        v-on="get_user.id == task_comment.user_id ? { click: () => toggleUpdateComment(task_comment) } : {}"
                                        :class="get_user.id == task_comment.user_id ? 'editable-comment' : 'non-editable-comment'">
                                        <div v-html="task_comment.comment" class="comment"></div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-show="task_comment.id == comment_to_edit.id">
                                        <wysiwyg v-model="task_comment.comment"
                                            @change="handleCommentEdit(task_comment.id)"
                                            :ref="'editorEdit_' + task_comment.id" />
                                        <div v-if="showUserListEdit" class="user-dropdown" :style="{ zIndex: '99' }">
                                            <ul>
                                                <li v-for="user in filteredUsers" :key="user.id"
                                                    @click="addUserToCommentEdit(user, task_comment.id)">
                                                    {{ user.name }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div :style="{ display: 'flex', marginTop: '55px' }">
                                            <div>
                                                <v-btn @click="deleteComment(task_comment)" color="red" dark
                                                    style="top:-44px;margin-left:7px;">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </div>
                                            <div>
                                                <v-btn @click="updateComment(task_comment)" color="primary"
                                                    style="top:-44px;margin-left:7px;">
                                                    Update
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-list-item-subtitle>

                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-footer absolute v-show="showNewComment">
                <v-row>
                    <v-col cols="12">
                        <wysiwyg v-model="new_task_comment" placeholder="Add a comment" @change="handleComment"
                            ref="editor" />
                        <div v-if="showUserList" class="user-dropdown" :style="{ zIndex: '99' }">
                            <ul>
                                <li v-for="user in filteredUsers" :key="user.id" @click="addUserToComment(user)">
                                    {{ user.name }}
                                </li>
                            </ul>
                        </div>
                        <div style="text-align:right;">
                            <v-btn v-if="showCommentButton" @click="createComment()" color="primary"
                                style="top:-44px;margin-right:7px;">Comment</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-footer>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'TaskNavigationComponent',
    props: [
        'task_details_dialog', 'can_close_task_details_dialog', 'users'
    ],
    data: () => ({
        loading: false,
        api_url: null,
        due_date_menu: false,
        new_subtask: {
            name: null,
            description: null,
            priority_id: null,
            due_date: null,
        },
        subtask_headers: [
            { text: 'Task Name', value: 'name', width: '40%' },
            { text: 'Assignee', value: 'assignee_id', width: '30%' },
            { text: 'Priority', value: 'priority_id', width: '15%' },
            { text: 'Status', value: 'status_id', width: '15%' },
            { text: '', width: '1%', sortable: false },
        ],
        navigation: {
            width: 700,
            borderSize: 5
        },
        show_filters_dialog: false,
        projects: [],
        create_quick_task: false,
        new_task_comment: null,
        comment_to_edit: {
            id: null,
        },
        showUserList: false,
        showUserListEdit: false,
        filteredUsers: [],
        searchQuery: '',
        showCommentButton: true,
        showNewComment: true,
        editorCaretPosition: 0
    }),
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
        this.loading = true
        this.getProjects()
        // this.setBorderWidth()
        // this.setEvents()
        const editor = this.$refs.editor.$el.querySelector('[contenteditable="true"]');
        editor.addEventListener('keydown', this.handleBackspaceOnSpan);
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
            get_tasks: 'tasks/get_tasks',
            get_priorities: 'tasks/get_priorities',
            get_statuses: 'tasks/get_statuses',
            get_selected_priorities: 'tasks/get_selected_priorities',
            get_selected_statuses: 'tasks/get_selected_statuses',
            get_selected_task: 'tasks/get_selected_task',
        }),
        selected_priorities: {
            get() {
                return this.get_selected_priorities
            },
            set(new_priorities) {
                this.$store.dispatch('tasks/set_selected_priorities', new_priorities)
            }
        },
        selected_statuses: {
            get() {
                return this.get_selected_statuses
            },
            set(new_statuses) {
                this.$store.dispatch('tasks/set_selected_statuses', new_statuses)
            }
        },
    },
    methods: {
        handleComment() {
            const editor = this.$refs.editor.$el.querySelector('[contenteditable="true"]');
            const plainText = editor.textContent;
            const atIndex = plainText.lastIndexOf('@');
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const caretPosition = range.endOffset;
            this.editorCaretPosition = caretPosition;
            if (atIndex !== -1) {
                let nextText = plainText.slice(atIndex + 1);
                let firstCharAfterAt = nextText.charAt(0);
                firstCharAfterAt = firstCharAfterAt.replace(/^\s+|\s+$/gm, '');
                if (firstCharAfterAt === '') {
                    this.filteredUsers = this.users.slice(0, 10);
                    this.showUserList = true;
                    this.showCommentButton = false;
                } else {
                    let exploded = nextText.split(" ");
                    if (exploded.length > 2) {
                        exploded.pop();
                    }
                    let implodedText = exploded.join(" ");
                    this.searchQuery = implodedText;
                    this.filteredUsers = this.users.filter(user =>
                        user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                    ).slice(0, 10);
                    if (this.filteredUsers.length === 0) {
                        implodedText = nextText[0];
                        this.searchQuery = implodedText;
                        this.filteredUsers = this.users
                            .filter(user => {
                                const searchRegex = new RegExp(`^${this.searchQuery.toLowerCase()}(\\s|$)`, 'i');
                                return searchRegex.test(user.name.toLowerCase());
                            })
                            .slice(0, 10);
                        if (this.filteredUsers.length === 0) {
                            this.showUserList = false;
                        } else {
                            this.showUserList = this.filteredUsers.length > 0;
                        }
                    }
                    this.showCommentButton = !this.showUserList;
                }
            } else {
                this.showUserList = false;
                this.showCommentButton = true;
            }
        },
        handleCommentEdit(task_comment_id) {
            const editorRef = this.$refs['editorEdit_' + task_comment_id][0];
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const caretPosition = range.endOffset;
            this.editorCaretPosition = caretPosition;

            if (editorRef && editorRef.$el) {
                const editor = editorRef.$el.querySelector('[contenteditable="true"]');
                if (editor) {
                    const plainText = editor.textContent;
                    const atIndex = plainText.lastIndexOf('@');
                    if (atIndex !== -1) {
                        let nextText = plainText.slice(atIndex + 1);
                        let firstCharAfterAt = nextText.charAt(0);
                        firstCharAfterAt = firstCharAfterAt.replace(/^\s+|\s+$/gm, '');
                        if (firstCharAfterAt === '') {
                            this.filteredUsers = this.users.slice(0, 10);
                            this.showUserListEdit = true;
                        } else {
                            let exploded = nextText.split(" ");
                            if (exploded.length > 2) {
                                exploded.pop();
                            }
                            let implodedText = exploded.join(" ");
                            this.searchQuery = implodedText;
                            this.filteredUsers = this.users.filter(user =>
                                user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                            ).slice(0, 10);
                            if (this.filteredUsers.length === 0) {
                                implodedText = nextText[0];
                                this.searchQuery = implodedText;
                                this.filteredUsers = this.users
                                    .filter(user => {
                                        const searchRegex = new RegExp(`^${this.searchQuery.toLowerCase()}(\\s|$)`, 'i');
                                        return searchRegex.test(user.name.toLowerCase());
                                    })
                                    .slice(0, 10);
                                if (this.filteredUsers.length === 0) {
                                    this.showUserList = false;
                                } else {
                                    this.showUserList = this.filteredUsers.length > 0;
                                }
                            }
                            this.showUserListEdit = this.filteredUsers.length > 0;
                        }
                    } else {
                        this.showUserListEdit = false;
                    }
                }
            } else {
                console.error('Editor reference not found or not mounted yet for task_comment_id:', task_comment_id);
            }
        },
        addUserToComment(user) {
            const editor = this.$refs.editor.$el.querySelector('[contenteditable="true"]');
            const walker = document.createTreeWalker(
                editor,
                NodeFilter.SHOW_TEXT,
                {
                    acceptNode(node) {
                        if (node.nodeValue.includes('@')) {
                            return NodeFilter.FILTER_ACCEPT;
                        }
                        return NodeFilter.FILTER_REJECT;
                    }
                },
                false
            );
            let foundAtSymbol = false;
            let textNodesToUpdate = [];
            while (walker.nextNode()) {
                let textNode = walker.currentNode;
                let textContent = textNode.nodeValue;
                let atIndex = textContent.lastIndexOf('@');
                if (atIndex + 1 === this.editorCaretPosition) {
                    foundAtSymbol = true;
                } else if (user['name'].toLowerCase().startsWith(textContent.slice(atIndex + 1, this.editorCaretPosition).toLowerCase())) {
                    foundAtSymbol = true;
                }

                if (foundAtSymbol) {
                    let beforeAt = textContent.slice(0, atIndex);
                    let afterAt = textContent.slice(atIndex, textContent.length);

                    afterAt = afterAt.replace("@" + this.searchQuery, '');
                    afterAt = afterAt.replace("@", '');
                    textNodesToUpdate.push({
                        textNode,
                        beforeAt,
                        afterAt,
                    });
                }
            }

            textNodesToUpdate.forEach(({ textNode, beforeAt, afterAt }) => {
                const mentionSpan = document.createElement('span');
                mentionSpan.style.color = '#1976d2';
                mentionSpan.setAttribute('data-email', user.email);
                mentionSpan.setAttribute('contenteditable', 'false');
                mentionSpan.textContent = user.name;

                const afterTextNode = document.createTextNode(afterAt);

                textNode.nodeValue = beforeAt;
                textNode.parentNode.insertBefore(mentionSpan, textNode.nextSibling);
                textNode.parentNode.insertBefore(afterTextNode, mentionSpan.nextSibling);
                this.$nextTick(() => {
                    const editor = this.$refs.editor.$el.querySelector('[contenteditable="true"]');
                    editor.focus();

                    const range = document.createRange();
                    const selection = window.getSelection();

                    if (afterTextNode) {
                        range.setStart(afterTextNode, afterTextNode.length);
                    } else {
                        range.setStartAfter(mentionSpan);
                    }

                    range.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(range);
                });
            });

            if (!foundAtSymbol) {
                console.log('No @ symbol found in the content');
            }
            this.showUserList = false;
            this.showCommentButton = true;


        },
        addUserToCommentEdit(user, task_comment_id) {
            const editorRef = this.$refs['editorEdit_' + task_comment_id][0];
            if (editorRef && editorRef.$el) {
                const editor = editorRef.$el.querySelector('[contenteditable="true"]')
                const walker = document.createTreeWalker(
                    editor,
                    NodeFilter.SHOW_TEXT,
                    {
                        acceptNode(node) {
                            if (node.nodeValue.includes('@')) {
                                return NodeFilter.FILTER_ACCEPT;
                            }
                            return NodeFilter.FILTER_REJECT;
                        }
                    },
                    false
                );

                let foundAtSymbol = false;
                let textNodesToUpdate = [];

                while (walker.nextNode()) {
                    let textNode = walker.currentNode;
                    let textContent = textNode.nodeValue;
                    let atIndex = textContent.lastIndexOf('@');
                    if (atIndex + 1 === this.editorCaretPosition) {
                        foundAtSymbol = true;
                    } else if (user['name'].toLowerCase().startsWith(textContent.slice(atIndex + 1, this.editorCaretPosition).toLowerCase())) {
                        foundAtSymbol = true;
                    }
                    if (foundAtSymbol) {
                        let beforeAt = textContent.slice(0, atIndex);
                        let afterAt = textContent.slice(atIndex, textContent.length);

                        afterAt = afterAt.replace("@" + this.searchQuery, '');
                        afterAt = afterAt.replace("@", '');
                        textNodesToUpdate.push({
                            textNode,
                            beforeAt,
                            afterAt,
                        });
                    }
                }

                textNodesToUpdate.forEach(({ textNode, beforeAt, afterAt }) => {
                    const mentionSpan = document.createElement('span');
                    mentionSpan.style.color = '#1976d2';
                    mentionSpan.setAttribute('data-email', user.email);
                    mentionSpan.setAttribute('contenteditable', 'false');
                    mentionSpan.textContent = user.name;

                    const afterTextNode = document.createTextNode(afterAt);

                    textNode.nodeValue = beforeAt;
                    textNode.parentNode.insertBefore(mentionSpan, textNode.nextSibling);
                    textNode.parentNode.insertBefore(afterTextNode, mentionSpan.nextSibling);

                    this.$nextTick(() => {
                        const editor = this.$refs.editor.$el.querySelector('[contenteditable="true"]');
                        editor.focus();

                        const range = document.createRange();
                        const selection = window.getSelection();

                        if (mentionSpan) {
                            range.setStart(mentionSpan, mentionSpan.length);
                        } else {
                            range.setStartAfter(mentionSpan);
                        }

                        range.collapse(true);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    });
                });

                if (!foundAtSymbol) {
                    console.log('No @ symbol found in the content');
                }
                this.showUserListEdit = false;
            }
        },
        handleBackspaceOnSpan(event) {
            if (event.key === 'Backspace') {
                const selection = window.getSelection();
                const range = selection.getRangeAt(0);
                const caretPosition = range.startContainer;

                if (caretPosition.nodeType === Node.TEXT_NODE && caretPosition.parentElement.tagName === 'SPAN') {
                    const spanElement = caretPosition.parentElement;
                    spanElement.remove();
                    event.preventDefault();
                    return;
                }


                if (caretPosition.nodeType === Node.TEXT_NODE) {
                    const textNode = caretPosition;
                    const offset = range.startOffset;

                    if (offset === 0 && textNode.previousSibling && textNode.previousSibling.tagName === 'SPAN') {
                        const spanElement = textNode.previousSibling;
                        spanElement.remove();
                        event.preventDefault();
                        return;
                    }
                }

                if (caretPosition.nodeType === Node.ELEMENT_NODE && caretPosition.tagName === 'SPAN') {
                    const spanElement = caretPosition;
                    spanElement.remove();
                    event.preventDefault();
                }
            }
        },
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        changeCompany() {
            if (this.get_selected_task.company_id == null) {
                this.get_selected_task.project_id = null
            }
            this.getProjects()
            this.updateTask()
        },
        async getProjects() {
            let payload = {
                company_id: this.get_selected_task.company_id,
            }
            this.$axios.get('api/projects/get_projects', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.projects = data.data
                    }
                })
        },
        closeTaskDetailsDialog() {
            this.$emit('closeTaskDetailsDialog')
        },
        async updateTask() {
            this.$emit('updateTask', this.get_selected_task)
        },
        openProject() {
            this.$store.dispatch('companies/set_selected_company', this.get_selected_task.company)
            this.$router.push({ name: 'ViewProject', params: { id: this.get_selected_task.project_id } })
        },
        openMeeting() {
            this.$store.dispatch('companies/set_selected_company', this.get_selected_task.company)
            this.$router.push({ name: 'ViewMeeting', params: { id: this.get_selected_task.meeting_id } })
        },
        onCloseConditional() {
            return this.task_details_dialog && this.can_close_task_details_dialog
        },
        includeFunction() {
            const tempArray = [document.querySelector('.included')]
            document.querySelectorAll('.v-select-list').forEach(select => {
                tempArray.push(select)
            })
            if (document.querySelector('.v-picker')) {
                tempArray.push(document.querySelector('.v-picker'))
            }
            return tempArray
        },
        openCreateQuickTask() {
            this.create_quick_task = true
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.quickSubtask.$refs.input.focus()
                })
            })
        },
        closeCreateQuickTask() {
            this.create_quick_task = false
            this.new_subtask = {
                name: null,
                description: null,
                priority_id: null,
                due_date: null,
            }
        },
        async createSubtask() {
            let payload = {
                task_id: this.get_selected_task.id,
                assignee_id: this.get_user.id,
                ...this.new_subtask
            }
            await this.$axios.post('api/tasks/create_subtask', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.new_subtask = {
                            name: null,
                            description: null,
                            priority_id: null,
                            due_date: null,
                        }
                        this.$store.dispatch('tasks/set_selected_task', data.data)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        getTasks() {
            this.$emit('getTasks')
        },
        async updateSubtask(item) {
            let payload = {
                assignee_id: item.assignee_id,
                task_id: item.task_id,
                name: item.name,
                status_id: item.status_id,
                description: item.description,
                priority_id: item.priority_id,
                due_date: item.due_date,
                allocated_time: item.allocated_time,
            }
            await this.$axios.post(`api/tasks/update_subtask/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async deleteSubtask(item) {
            await this.$axios.delete(`api/tasks/delete_subtask/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getTasks()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        scrollToBottom() {
            this.$nextTick(() => {
                var container = this.$el.querySelector("#navigation")
                if (container) {
                    container.scrollTop = container.scrollHeight
                }
            })
        },
        async createComment() {
            const editor = this.$refs.editor.$el.querySelector('[contenteditable="true"]');
            let commentContent = editor.innerHTML;

            let payload = {
                task_id: this.get_selected_task.id,
                user_id: this.get_user.id,
                comment: commentContent,
            }
            this.scrollToBottom()
            await this.$axios.post('api/tasks/create_task_comment', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$store.dispatch('tasks/set_selected_task', data.data)
                        this.scrollToBottom()
                        this.getTasks()
                        this.new_task_comment = null
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        toggleUpdateComment(task_comment) {
            this.comment_to_edit.id = task_comment.id
            this.showNewComment = false;
        },
        async updateComment(task_comment) {
            const editorRef = this.$refs['editorEdit_' + task_comment.id][0];
            if (editorRef && editorRef.$el) {
                const editor = editorRef.$el.querySelector('[contenteditable="true"]');
                let commentContent = editor.innerHTML;
                task_comment.comment = commentContent;
                await this.$axios.patch(`api/tasks/update_task_comment/${task_comment.id}`, task_comment)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$toast.success(data.message);
                            this.comment_to_edit.id = null;
                            this.showNewComment = true;
                        } else {
                            this.$toast.error(data.message);
                        }
                    });
            }

        },
        async deleteComment(task_comment) {
            await this.$axios.delete(`api/tasks/delete_task_comment/${task_comment.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getTasks()
                        this.$emit('closeTaskDetailsDialog')
                        this.showNewComment = true;
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(
                ".v-navigation-drawer__border"
            )
            i.style.width = this.navigation.borderSize + "px"
            i.style.cursor = "ew-resize"
        },
        setEvents() {
            const minSize = this.navigation.borderSize
            const el = this.$refs.drawer.$el
            const drawerBorder = el.querySelector(".v-navigation-drawer__border")
            const vm = this
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left"

            function resize(e) {
                document.body.style.cursor = "ew-resize"
                let f = direction === "right"
                    ? document.body.scrollWidth - e.clientX
                    : e.clientX
                el.style.width = f + "px"
            }
            drawerBorder.addEventListener(
                "mousedown",
                function (e) {
                    if (e.offsetX < minSize) {
                        el.style.transition = 'initial'
                        document.addEventListener("mousemove", resize, false)
                    }
                },
                false
            )
            document.addEventListener(
                "mouseup",
                function () {
                    el.style.transition = ''
                    vm.navigation.width = el.style.width
                    document.body.style.cursor = ""
                    document.removeEventListener("mousemove", resize, false)
                },
                false
            )
        },
        getColor(name) {
            const letterColorMap = {
                A: 'red lighten-2',
                B: 'blue lighten-2',
                C: 'green lighten-2',
                D: 'orange lighten-2',
                E: 'purple lighten-2',
                F: 'pink lighten-2',
                G: 'teal lighten-2',
                H: 'amber lighten-2',
                I: 'indigo lighten-2',
                J: 'lime lighten-2',
                K: 'brown lighten-2',
                L: 'cyan lighten-2',
                M: 'deep-orange lighten-2',
                N: 'deep-purple lighten-2',
                O: 'light-blue lighten-2',
                P: 'light-green lighten-2',
                Q: 'blue-grey lighten-2',
                R: 'red darken-2',
                S: 'blue darken-2',
                T: 'green darken-2',
                U: 'orange darken-2',
                V: 'purple darken-2',
                W: 'pink darken-2',
                X: 'teal darken-2',
                Y: 'amber darken-2',
                Z: 'indigo darken-2'
            }
            const firstLetter = name.charAt(0).toUpperCase()
            return letterColorMap[firstLetter] || 'grey lighten-2'
        },
    },
}
</script>

<style lang="scss" scoped>
:deep(.hide-border>.v-input__control>.v-input__slot:before, .hide-border>.v-input__control>.v-input__slot:after) {
    border: none !important;
}

:deep(::-webkit-scrollbar) {
    width: 10px;
}

:deep(::-webkit-scrollbar-thumb) {
    border-radius: 2px;
    /* background-color: rgba(0,0,0,.5); */
    background-color: #a5a5a5;
    color: #a5a5a5;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.editable-comment {
    cursor: pointer;
}

.editable-comment:hover {
    background-color: rgb(235, 235, 235);
}

.non-editable-comment {
    cursor: initial;
}

.user-dropdown {
    //   position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.user-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}

.user-dropdown li:hover {
    background-color: #f5f5f5;
}


:deep(.comment) {
    display: contents !important;
    white-space: initial;
}
</style>